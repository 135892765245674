<template>
  <div>
    <div class="app-container">
      <el-form
        :model="dataForm"
        ref="dataForm"
        label-width="100px"
        class="demo-dataForm"
      >
        <el-form-item label="姓名" required>
          <el-input
            style="width: 50%"
            placeholder="请输入标题"
            v-model="dataForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" required>
          <el-input
            style="width: 50%"
            placeholder="请输入标题"
            v-model="dataForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          {{ dataForm.deptName }}
        </el-form-item>
        <el-form-item label="角色">
          {{ dataForm.roleName }}
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="upload-demo"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            style="margin: 20px 0"
            :action="uploadUrl"
            :on-change="handleChange"
            list-type="picture-card"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
          >
            <img
              v-if="dataForm.avatar"
              :src="dataForm.avatar"
              class="avatar"
              style="width: 100%"
            />
            <i v-else class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <!-- 1 -->
          <el-button type="primary" @click="submitSubject()">保存</el-button>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getImgUrl } from "@/utils";
import qs from "qs";
export default {
  data() {
    return {
      fileUrl: "",
      time: "",
      cityInfo: "",
      url: "", //后台获取token地址
      myFileList: [],
      typeArr: [],
      uploadUrl: "",
      images: [],
      imageUrlList: [],
      expiration: "",
      fileList: [],
      files: 10,
      dialogImageUrl: "",
      dialogVisible: false,
      select1: "",
      dataForm: {
        informationTitle: "",
        informationType: "",
        informationAuthor: "",
        publishTime: "",
        avatar: "",
      },
    };
  },
  components: {},
  mounted() {
    this.uploadUrl = this.$global.baseURL + "/aliyun/oss/upload";

    this.getDataInfo();
  },

  methods: {
    getDataInfo() {
      var that = this;
      this.$http({
        url: this.$http.adornUrl("/organBusiness/info"),
        method: "get",
      }).then(({ data }) => {
        this.dataForm = data.data;
        this.dataForm.avatar = this.dataForm.avatar;
      });
    },
    getPicList() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl(
          "/sysPic/listPic?pageNum=1&pageSize=999&actionId=" +
            this.dataForm.id +
            "&picType=1"
        ),
        method: "get",
      }).then(({ data }) => {
        for (let i = 0; i < data.data.length; i++) {
          let obj = {
            url: data.data[i].picUrl,
            id: data.data[i].id,
          };
          this.myFileList.push(obj);
          this.myOldFileList.push(obj);
        }
      });
    },
    getPorjType() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=资讯类型"),
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },

    submitSubject() {
      var that = this;
      if (this.dataForm.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (this.dataForm.phone == "") {
        this.$message.error("请输入手机号");
        return;
      }
      that.$delete(this.dataForm, "teamsManages");
      that
        .$http({
          url: that.$http.adornUrl("/organBusiness/updateInfo"),
          method: "post",
          headers: {
            token: that.$cookie.get("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(this.dataForm),
        })
        .then(({ data }) => {
          if (data.status) {
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.dataForm.userName = this.dataForm.name;
                localStorage.userInfo = JSON.stringify(this.dataForm);
                this.$store.commit("common/updateImageUrl", this.dataForm.avatar);
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    /**
     * 移除图片
     */
    handleRemove(file, fileList) {
      for (let i = 0; i < this.myFileList.length; i++) {
        if (file.url == this.myFileList[i].url) {
          this.myFileList.splice(i, 1);
        }
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确认移除图片？`);
    },
    handleSuccess(response, file, fileList) {
      this.dataForm.avatar = response.result.filePath;
    },
    getDate() {
      const date = new Date(),
        year = date.getFullYear(),
        month =
          date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
        day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
        hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
        mm =
          date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
      return `${year}${month}${day}${hh}${mm}`;
    },

    initCKEditor() {
      var that = this;
      var myFile = [];
      var index = 0;
      class UploadAdapter {
        constructor(loader) {
          var self = this;
          self.loader = loader;
          loader.file.then(function (result) {
            myFile.push(result);
          });
        }

        upload() {
          let size = myFile[index].size;
          const obj = new FormData();
          obj.append("file", myFile[index]);

          index++;
          return new Promise((resolve, reject) => {
            // 							if (size / 1024 > 2048) {
            // 								self.$message({
            // 									message: '图片不能大于2M'
            // 								});
            // 								self.editor.setData(self.dataForm.eventNotice);
            // 								return false;
            // 							};

            self.fullscreenLoading = true;
            that
              .$http({
                url: that.$http.adornUrl("/aliyun/oss/upload"),
                method: "post",
                headers: {
                  "Content-Type": "multipart/form-data",
                  // 'token': that.$cookie.get('token'),
                },
                data: obj,
              })
              .then(({ data }) => {
                self.fullscreenLoading = false;
                resolve({
                  default: data.result.filePath,
                });
                // 								if (data.success) {
                // 									resolve({
                // 										default: process.env.BASE_img + data.data.pic_url
                // 									});
                // 								} else {
                // 									that.$message({
                // 										message: data.data.msg
                // 									});
                // 									self.editor.setData(that.dataForm.eventNotice);
                // 								}
              });
          }).catch((error) => {
            console.log(error);
          });
        }

        abort() {}
      }
      //初始化编辑器
      CKEditor.create(document.querySelector("#editor"), {
        removePlugins: ["MediaEmbed"], //除去视频按钮
        language: "zh-cn", // 中文
        ckfinder: {
          uploaded: 1,
          url: "file/upload",
          // 后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
        },
      })
        .then((editor) => {
          if (
            that.dataForm.informationContent != null &&
            that.dataForm.informationContent != undefined
          ) {
            editor.setData(that.dataForm.informationContent);
          }

          const toolbarContainer = document.querySelector("#toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          // 加载了适配器
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new UploadAdapter(loader);
          };
          this.editor = editor; // 将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
        })
        .catch((error) => {
          console.error(error);
        });
    },

    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      if (this.libraryType == "1") {
        let isLt2M = file.size / 1024 / 1024 < 10;
        if (
          type != ".doc" &&
          type != ".docx" &&
          type != ".pdf" &&
          type != ".xls" &&
          type != ".xlsx" &&
          type != ".ppt" &&
          type != ".pptx"
        ) {
          this.$message.error(
            "上传文件只能是.doc .docx .xls .xlsx .ppt .pptx .pdf 格式!"
          );
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 10MB!");
          this.fileList = [];
          return false;
        }
        return true;
      } else if (this.libraryType == "2") {
        let isLt2M = file.size / 1024 / 1024 < 50;
        if (type != ".wav" && type != ".mp3" && type != ".wma") {
          this.$message.error("上传文件只能是.wav .mp3 .wma格式!");
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 50MB!");
          this.fileList = [];
          return false;
        }
        return true;
      } else if (this.libraryType == "3") {
        let isLt2M = file.size / 1024 / 1024 < 200;
        if (type != ".mp4") {
          this.$message.error("上传文件只能是.mp4 .mkv .avi .rmvb格式!");
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 200MB!");
          this.fileList = [];
          return false;
        }
        return true;
      }
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      // 				console.log(this.fileList)
      // 				let obj={
      // 					url:process.env.BASE_img+ file.response.pic_url,
      // 					fileName:file.name
      // 					}
      // 				this.myFileList.push(obj)
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    cityInfo(val) {
      console.log(val);
      this.center = val.locationCity.label + val.locationCounty.label;
    },
  },
};
</script>
<style scoped>
.el-input__inner {
  width: 50%;
}
.el-input-number--medium {
  width: 50%;
  line-height: 34px;
}
.el-input-number .el-input__inner {
  -webkit-appearance: none;
  padding-left: 50px;
  padding-right: 50px;
  text-align: left !important;
}
.bm-view {
  width: 500px;
  height: 300px;
}

.app-container {
  margin-top: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #0476F5;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 147px;
  height: 147px;
  line-height: 147px;
  text-align: center;
}

.avatar {
  height: 146px;
  display: block;
}
</style>
